import ImageOpt from './ImageOpt';
import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { EffectCoverflow, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const DestinationSlider = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1,
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const handleNextClickStay = () => {
    const swiper = document.querySelector(
      '.subDestinations-slider-row .swiper'
    ).swiper;
    swiper.slideNext();
  };

  const handlePrevClickStay = () => {
    const swiper = document.querySelector(
      '.subDestinations-slider-row .swiper'
    ).swiper;
    swiper.slidePrev();
  };

  return (
    <section
      className={`experiences-slider lg:py-60 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="section-title text-center fade-ani"
        >
          {module?.heading && (
            <motion.div variants={item} className="title-black">
              <h3>{module.heading}</h3>
            </motion.div>
          )}
          {module?.description && (
            <motion.div variants={item} className="content ">
              {parse(module?.description)}
            </motion.div>
          )}
        </motion.div>
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container2}
          id="subDestinations-slider-row"
          className="subDestinations-slider-row relative overflow-hidden subDestinations-drag-section experiences-slider-grid lg:mt-70 mt-30 fade-ani"
        >
          {module?.destination?.length > 0 && (
            <>
              <Swiper
                effect={'coverflow'}
                coverflowEffect={{
                  stretch: 200,
                  rotate: 0,
                  depth: 10,
                  modifier: 1,
                  slideShadows: false,
                }}
                centeredSlides={true}
                loop={true}
                slidesPerView={'auto'}
                pagination={{
                  el: '.destination-slider-pagination',
                  type: 'progressbar',
                }}
                modules={[EffectCoverflow, Pagination, Scrollbar]}
                className="mySwiper"
                breakpoints={{
                  100: {
                    slidesPerView: 1.2,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 1.8,
                    spaceBetween: 0,
                    coverflowEffect: {
                      stretch: 100,
                      rotate: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: false,
                    },
                  },
                  1024: {
                    slidesPerView: 2.17,
                    spaceBetween: 0,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 380,
                      depth: 100,
                      modifier: 1,
                      slideShadows: false,
                    },
                  },
                  1440: {
                    slidesPerView: 2.33,
                    spaceBetween: 0,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 380,
                      depth: 100,
                      modifier: 1,
                      slideShadows: false,
                    },
                  },
                }}
              >
                {module?.destination?.map((destination) => (
                  <SwiperSlide key={destination.id}>
                    <Link to={destination?.uri} className="hover:cursor-grab">
                      <ImageOpt
                        imgData={destination?.featuredImage?.node.mediaItemUrl}
                        imgAlt={destination?.featuredImage?.node?.altText}
                        imgClass={'!mr-0 !w-full'}
                        width={558}
                        height={375}
                      />
                    </Link>
                    <Link to={destination?.uri}>
                      <div className="ex-slider-content text-center px-20 py-20 hover:cursor-pointer">
                        <div className="title-black">
                          <h6>{destination?.title}</h6>
                        </div>
                        {destination?.excerpt && (
                          <div className="content ">
                            {parse(destination.excerpt)}
                          </div>
                        )}
                        <div className="btn-custom flex subDestinations-btn-ignore  flex-wrap items-center space-x-8 mt-15 justify-center ">
                          <div className="ignore-btn">
                            <span className="button btn-red-border">
                              Explore Region
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-arrow swiper-arrow-red">
                <a
                  className="button-prev cursor-pointer absolute top-[30%] left-[24vw] lgscreen:left-[17.5vw] mdscreen:left-[10px] translate-y-minus_50 lgscreen:top-[28%] mdscreen:top-[21%] z-9"
                  onClick={handlePrevClickStay}
                >
                  <img
                    src="/images/white-arrow.svg"
                    loading="lazy"
                    className="scale-x-[-1]"
                    alt=""
                  />
                </a>
                <a
                  className="button-next cursor-pointer absolute top-[30%] lgscreen:top-[28%] mdscreen:top-[21%] right-[24vw] lgscreen:right-[17.5vw] mdscreen:right-[10px] translate-y-minus_50 z-9"
                  onClick={handleNextClickStay}
                >
                  <img src="/images/white-arrow.svg" loading="lazy" alt="" />
                </a>
              </div>
              <div className="trip-slider__bottom-bar mt-10 mdscreen:mt-0 px-70 lgscreen:px-30">
                <div className="trip-slider__scrollbar relative flex-1 overflow-hidden left-0 bottom-0 h-[2px]">
                  {/* <!-- swiper progressbar --> */}
                  <div className="slider-progressbar destination-slider-pagination swiper-pagination"></div>
                </div>
              </div>
            </>
          )}
        </motion.div>
      </div>
    </section>
  );
};

export default DestinationSlider;
export const NavigationContent_DestinationSliderFragment = graphql`
  fragment NavigationContent_DestinationSliderFragment on WpDestination_Destinationcontent_DestinationContent_NavigationContent_tabs_DestinationContent_DestinationSlider {
    id
    extraClass
    hideSection
    heading
    description
    destination {
      ... on WpDestination {
        id
        featuredImage {
          node {
            altText
            mediaItemUrl
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 2000)
          }
        }
        title
        uri
        excerpt
      }
    }
  }
`;
